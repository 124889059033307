import { Injectable, OnDestroy } from '@angular/core';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { Observable, Subject, timer } from 'rxjs';
import { catchError, delayWhen, retryWhen, switchMap, tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements OnDestroy {
  private socket$: WebSocketSubject<any> | null = null;
  private reconnectSubject = new Subject<void>();
  private reconnectAttempts = 0;
  private maxReconnectAttempts = 10;
  private reconnectInterval = 1000;
  private heartbeatInterval: any;
  private userId = '';

  constructor() {
    this.reconnectSubject.pipe(
      tap(() => console.log(`Attempting to reconnect... Attempt #${this.reconnectAttempts}`)),
      switchMap(() => this.createWebSocketConnection())
    ).subscribe(
      () => {
        this.reconnectAttempts = 0;
        console.log('Reconnected successfully');
        this.startHeartbeat();
      },
      (error) => {
        console.error('WebSocket reconnection error:', error);
        this.scheduleReconnection();
      }
    );
  }

  connect(userId: string) {
    this.userId = userId;
    this.initializeWebSocket();
  }

  private initializeWebSocket() {
    const url = `${environment.signalingServerUrl}/${this.userId}`;
    this.socket$ = webSocket(url);
    this.socket$.subscribe(
      () => {
        this.reconnectAttempts = 0;
        this.startHeartbeat();
      },
      (error) => {
        console.error('WebSocket error:', error);
        this.scheduleReconnection();
      },
      () => {
        console.log('WebSocket closed');
        this.scheduleReconnection();
      }
    );
  }

  sendMessage(message: any) {
    console.log('messaging', message)
    console.log(this.socket$)
    this.socket$?.next(message);
  }

  getMessages(): Observable<any> | undefined {
    return this.socket$?.asObservable();
  }

  private createWebSocketConnection(): Observable<void> {
    return new Observable<void>((observer) => {
      if (!this.socket$ || this.socket$.closed) {
        this.initializeWebSocket();
      }
      observer.next();
      observer.complete();
    });
  }

  private scheduleReconnection() {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts++;
      const reconnectDelay = this.reconnectInterval * Math.pow(2, this.reconnectAttempts);
      setTimeout(() => this.reconnectSubject.next(), reconnectDelay);
    } else {
      console.error('Max reconnection attempts reached.');
    }
  }

  private startHeartbeat() {
    this.stopHeartbeat();
    this.heartbeatInterval = setInterval(() => {
      this.sendMessage({ type: 'ping' });
    }, 60000);
  }

  private stopHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  ngOnDestroy() {
    this.stopHeartbeat();
    this.socket$?.complete();
  }
}