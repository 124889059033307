<div mat-dialog-content>
    <div class="flex-column">
        <mat-form-field class="mat-pickers">
            <mat-label> Device </mat-label>
            <mat-select [value]="device" (valueChange)="device = $event">
                <mat-option *ngFor="let device of input.unassignedDevices" [value]="device">
                    {{ device.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-button (click)="save()">Save</button>
</div>
