import { BehaviorSubject, Observable } from '@lib/rxjs';
import { CoordObj } from './../app/utilities/map/map.component';
import { ChangeDetectorRef, Injectable, EventEmitter } from '@angular/core';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import View from 'ol/View';
import { fromLonLat, toLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import Circle from 'ol/geom/Circle';
import LineString from 'ol/geom/LineString';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill, Icon, Circle as CircleStyle, RegularShape } from 'ol/style';
import { Color as ThreeColor } from 'three';
import { Point } from 'ol/geom';
import { Draw, Modify, Snap } from 'ol/interaction';
import { asString, asArray } from 'ol/color';
import { googleMapsLayer } from '@app/utilities/map/ol/satellite-layer';

@Injectable({
    providedIn: 'root',
})
export class MapOverlayController {
    private map: Map;
    private vectorSource: VectorSource;
    private drawingLayer: VectorLayer | null = null;
    private cdr: ChangeDetectorRef;
    public isPersistentTooltipSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isPersistentTooltip$: Observable<boolean> = this.isPersistentTooltipSubject.asObservable();
    public isMachineTooltipSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isMachineTooltip$: Observable<boolean> = this.isMachineTooltipSubject.asObservable();
    public isDeviceTooltipSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isDeviceTooltip$: Observable<boolean> = this.isDeviceTooltipSubject.asObservable();

    constructor() { }

    public setMapInstance(map: Map): void {
        this.map = map;
    }

    public toggleTooltipMode(subject: BehaviorSubject<boolean>): void {
        console.log(subject.value, `Triggered for ${subject}`);
        const currentValue = subject.value;
        subject.next(!currentValue);
    }

    public initializeDrawingManager(): VectorSource {
        if (!this.map) {
            console.error('Map instance is not set.');
            throw new Error('Map instance must be initialized before calling this method.');
        }

        if (this.drawingLayer) {
            return this.drawingLayer.getSource() as VectorSource; // Return existing source
        }

        this.drawingLayer = new VectorLayer({
            source: this.vectorSource,
            style: new Style({
                fill: new Fill({ color: 'rgba(255, 255, 255, 0.4)' }),
                stroke: new Stroke({ color: '#3399CC', width: 2 }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({ color: '#3399CC' }),
                }),
            }),
        });

        this.map.addLayer(this.drawingLayer);
        return this.vectorSource;
    }

    public addDrawInteraction(map: Map, drawType: 'Point' | 'Polygon' | 'LineString') {
        const vectorSource = this.initializeDrawingManager();

        const drawInteraction = new Draw({
            source: vectorSource,
            type: drawType,
        });

        this.map.addInteraction(drawInteraction);
        return drawInteraction;
    }

    public addModifyAndSnapInteractions(map: Map) {
        if (!this.drawingLayer) {
            console.error('Drawing layer not initialized.');
            return;
        }

        const vectorSource = this.drawingLayer.getSource() as VectorSource;

        const modifyInteraction = new Modify({ source: vectorSource });
        const snapInteraction = new Snap({ source: vectorSource });

        map.addInteraction(modifyInteraction);
        map.addInteraction(snapInteraction);
    }

    public removeInteraction(map: Map, interaction: Draw | Modify | Snap) {
        this.map.removeInteraction(interaction);
    }

    public initializeBaseSatelliteLayer(
        mapElement: HTMLElement,
        center: [number, number],
        zoom: number
    ) {
        // Initialize the OpenLayers map with Google Maps as the base layer
        this.map = new Map({
            target: mapElement,
            layers: [googleMapsLayer],
            view: new View({
                center: fromLonLat(center),
                zoom: zoom,
                projection: 'EPSG:3857',
            }),
        });
        return this.map;
    }
    
    addMarker(
        coordinate: [number, number],
        content: string,
        emitter: EventEmitter<CoordObj>,
        style?: any,
        icon?: string,
        draggable: boolean = false,
        isDevice?: boolean
    ) {
        const vectorSource = new VectorSource();

        // Create a feature for the location pin
        const markerFeature = new Feature({
            geometry: new Point(fromLonLat(coordinate)), // Convert to map projection
            name: content, // Store the content for the tooltip
        });

        vectorSource.addFeature(markerFeature);

        let markerStyle: Style[];

        // Define marker style (icon + circle)
        if (icon) {
            const iconStyle = new Style({
                image: new Icon({
                    src: icon,
                    scale: style?.iconScale || 0.5, // Scale the icon
                    anchor: [0.5, 0.5], // Center the icon
                }),
                zIndex: 1,
            });

            const circleStyle = new Style({
                image: new CircleStyle({
                    radius: style?.radius || 30, // Circle radius
                    fill: new Fill({ color: style?.color || '#5271FF' }), // Circle fill color
                    stroke: new Stroke({
                        color: style?.strokeColor || '#ffffff', // Circle border color
                        width: 2, // Circle border width
                    }),
                }),
                zIndex: 0,
            });

            markerStyle = [circleStyle, iconStyle];
        } else {
            markerStyle = [
                new Style({
                    image: new CircleStyle({
                        radius: style?.radius || 30,
                        fill: new Fill({ color: style?.color || '#5271FF' }),
                        stroke: new Stroke({
                            color: style?.strokeColor || '#ffffff',
                            width: 2,
                        }),
                    }),
                }),
            ];
        }

        let isPointerOverFeature = false;

        // Ripple effect style on hover
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: (feature) => {
                if (isPointerOverFeature) {
                    // Ripple effect on hover
                    const baseRadius = style?.radius || 30;
                    const rippleCircles = [
                        { radius: baseRadius + 20, opacity: 0.35, strokeOpacity: 1, strokeWidth: 2 },
                        { radius: baseRadius + 35, opacity: 0.15, strokeOpacity: 0.3, strokeWidth: 1.5 },
                        { radius: baseRadius + 50, opacity: 0.05, strokeOpacity: 0.1, strokeWidth: 1 },
                    ];

                    const rippleStyles = rippleCircles.map((ripple) => {
                        // Extracting the fill color and applying opacity
                        const baseFillColor = asString(
                            asArray(style?.color || '#5271FF')  // Default color if not provided
                                .slice(0, 3)  // RGB components
                                .concat(ripple.opacity)  // Adding opacity to the color
                        );

                        // Extracting stroke color and applying opacity for the border
                        const baseStrokeColor = asString(
                            asArray(style?.color || '#ffffff')  // Default stroke color if not provided
                                .slice(0, 3)  // RGB components
                                .concat(ripple.strokeOpacity)  // Adding opacity for stroke
                        );

                        return new Style({
                            image: new CircleStyle({
                                radius: ripple.radius,
                                fill: new Fill({ color: baseFillColor }),  // Fill color with opacity
                                stroke: new Stroke({
                                    color: baseStrokeColor,  // Stroke color with opacity
                                    width: ripple.strokeWidth,
                                }),
                            }),
                        });
                    });

                    // Combine ripple styles with the base marker style
                    return [...rippleStyles, ...markerStyle];
                }
                // Return the default marker style when not hovering
                return markerStyle;
            },
            zIndex: icon ? 1000 : 900,
        });

        this.map.addLayer(vectorLayer);

        // Tooltip element
        const tooltipElement = document.createElement('div');
        tooltipElement.className = 'ol-tooltip';
        tooltipElement.style.position = 'absolute';
        tooltipElement.style.backgroundColor = 'rgba(255, 255, 255, 0.95)';
        tooltipElement.style.color = 'black';
        tooltipElement.style.padding = '0.25em';
        tooltipElement.style.borderRadius = '0.15em';
        tooltipElement.style.whiteSpace = 'nowrap';
        tooltipElement.style.fontSize = '0.75em';
        tooltipElement.style.pointerEvents = 'none';
        tooltipElement.style.display = 'none';

        const tooltipOverlay = new Overlay({
            element: tooltipElement,
            positioning: 'center-center',
            offset: [0, -50],
            stopEvent: false,
        });

        this.map.addOverlay(tooltipOverlay);

        // Hover and tooltip logic
        this.map.on('pointermove', (event) => {
            const features = this.map.getFeaturesAtPixel(event.pixel, {
                hitTolerance: 2,
                layerFilter: (layer) => layer === vectorLayer,
            });

            const targetElement = this.map.getTargetElement();

            if (features && features.length > 0) {
                const feature = features[0];
                const name = feature.get('name');
                const geometry = feature.getGeometry();

                if (!isPointerOverFeature) {
                    targetElement.style.cursor = 'pointer';
                    isPointerOverFeature = true;
                    vectorLayer.changed();
                }

                if (name && geometry instanceof Point) {
                    const coordinates = geometry.getCoordinates();

                    // Case 1: If isDeviceTooltipSubject is true, show "Device: " tooltip persistently
                    const isDevice = name.includes('Device: ');
                    if (this.isDeviceTooltipSubject.value && isDevice) {
                        tooltipElement.innerHTML = name;
                        tooltipOverlay.setPosition(coordinates);
                        tooltipElement.style.display = 'block';
                    }

                    // Case 2: If isMachineTooltipSubject is true, show "Machine: " tooltip persistently
                    const isMachine = name.includes('Machine: ');
                    if (this.isMachineTooltipSubject.value && isMachine) {
                        tooltipElement.innerHTML = name;
                        tooltipOverlay.setPosition(coordinates);
                        tooltipElement.style.display = 'block';
                    }

                    // Case 3: If neither flag is true, show tooltip on hover for other content
                    if (!this.isDeviceTooltipSubject.value && !this.isMachineTooltipSubject.value) {
                        if (tooltipElement.innerHTML !== 'Device: ' && tooltipElement.innerHTML !== 'Machine: ') {
                            tooltipElement.innerHTML = name;
                            tooltipOverlay.setPosition(coordinates);
                            tooltipElement.style.display = 'block';
                        }
                    }
                }
            } else {
                // Hide the tooltip when not hovering over a feature
                if (isPointerOverFeature) {
                    targetElement.style.cursor = 'default';
                    isPointerOverFeature = false;
                    vectorLayer.changed();
                }

                // Keep the tooltip visible persistently when either flag is true
                tooltipElement.style.display = (this.isDeviceTooltipSubject.value || this.isMachineTooltipSubject.value) ? 'block' : 'none';
            }

        });

        // Add Modify interaction if draggable
        if (draggable) {
            const modifyInteraction = new Modify({
                source: vectorSource,
            });

            this.map.addInteraction(modifyInteraction);

            modifyInteraction.on('modifyend', (event) => {
                event.features.forEach((feature) => {
                    const geometry = feature.getGeometry() as Point;
                    if (geometry) {
                        const coordinates = toLonLat(geometry.getCoordinates());
                        const coordObj = { lat: coordinates[1], lng: coordinates[0] };
                        emitter.emit(coordObj);
                    }
                });
            });
        }

        return markerFeature;
    }

    addBlastSites(blastSites: any[]): void {
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: new Style({
                fill: new Fill({ color: 'rgba(75, 75, 75, 0.5)' }),
                stroke: new Stroke({ color: 'rgba(0,0,0,1)', width: 2 }),
            }),
        });

        this.map.addLayer(vectorLayer);

        // Function to clear existing polygons and hide tooltips
        const clearMap = () => {
            vectorSource.clear(); // Clear all polygons
            document.querySelectorAll('.ol-tooltip').forEach((tooltipElement) => {
                (tooltipElement as HTMLElement).style.display = 'none'; // Hide tooltips
            });
        };

        // Function to update tooltips based on the mode
        const updateTooltips = () => {
            if (this.isPersistentTooltipSubject.value) {
                clearMap();
                // Add persistent tooltips for each blast site
                blastSites.forEach((site) => {
                    const coordinates = site.coordinates.map(([lat, lng]: [number, number]) =>
                        fromLonLat([lng, lat])
                    );

                    // Create a polygon for the blast site
                    const polygon = new Feature(new Polygon([coordinates]));
                    polygon.set('name', site.name);
                    vectorSource.addFeature(polygon);

                    // Tooltip element
                    const tooltipElement = document.createElement('div');
                    tooltipElement.className = 'ol-tooltip';
                    tooltipElement.style.position = 'absolute';
                    tooltipElement.style.backgroundColor = 'rgba(255, 255, 255, 0.95)';
                    tooltipElement.style.color = 'black';
                    tooltipElement.style.padding = '0.25em';
                    tooltipElement.style.borderRadius = '0.15em';
                    tooltipElement.style.whiteSpace = 'nowrap';
                    tooltipElement.style.fontSize = '0.75em';
                    tooltipElement.innerHTML = `<strong>Blast Site: </strong> ${site.name}`;

                    const tooltipOverlay = new Overlay({
                        element: tooltipElement,
                        positioning: 'center-center',
                        stopEvent: false,
                    });

                    this.map.addOverlay(tooltipOverlay);

                    const geometry = polygon.getGeometry() as Polygon;
                    const center = geometry.getInteriorPoint().getCoordinates();
                    tooltipOverlay.setPosition(center);
                    tooltipElement.style.display = 'block'; // Ensure tooltips are visible
                });
            } else {
                clearMap();
                blastSites.forEach((site) => {
                    const coordinates = site.coordinates.map(([lat, lng]: [number, number]) =>
                        fromLonLat([lng, lat])
                    );

                    // Create a polygon for the blast site
                    const polygon = new Feature(new Polygon([coordinates]));
                    polygon.set('name', site.name);
                    vectorSource.addFeature(polygon);
                });

                // Add hover tooltips
                const tooltipElement = document.createElement('div');
                tooltipElement.className = 'ol-tooltip';
                tooltipElement.style.position = 'absolute';
                tooltipElement.style.backgroundColor = 'rgba(255, 255, 255, 0.95)';
                tooltipElement.style.color = 'black';
                tooltipElement.style.padding = '0.25em';
                tooltipElement.style.borderRadius = '0.15em';
                tooltipElement.style.whiteSpace = 'nowrap';
                tooltipElement.style.fontSize = '0.75em';
                tooltipElement.style.pointerEvents = 'none';
                tooltipElement.style.cursor = 'pointer';
                tooltipElement.style.display = 'none'; // Initially hidden

                const tooltipOverlay = new Overlay({
                    element: tooltipElement,
                    positioning: 'center-center',
                    stopEvent: false,
                });

                this.map.addOverlay(tooltipOverlay);

                this.map.on('pointermove', (event) => {
                    const features = this.map.getFeaturesAtPixel(event.pixel);
                    if (features && features.length > 0) {
                        const feature = features[0];
                        const name = feature.get('name');
                        if (name) {
                            const geometry = feature.getGeometry() as Polygon;
                            const center = geometry.getInteriorPoint().getCoordinates();

                            tooltipElement.innerHTML = `<strong>Blast Site: </strong> ${name}`;
                            tooltipOverlay.setPosition(center);
                            tooltipElement.style.display = 'block';
                        }
                    } else {
                        tooltipElement.style.display = 'none';
                    }
                });
            }
        };

        this.isPersistentTooltip$.subscribe(() => {
            updateTooltips();
        });

        updateTooltips();
    }

    addCircle(center: [number, number], radius: number, color: string) {
        const circle = new Feature({
            geometry: new Circle(fromLonLat(center), radius),
        });

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [circle],
            }),
            style: new Style({
                stroke: new Stroke({
                    color,
                    width: 2,
                }),
                fill: new Fill({
                    color: `${color}55`, // Add transparency to fill
                }),
            }),
        });

        this.map.addLayer(vectorLayer);
    }

    addPolyline(coordinates: [number, number][], color: any) {
        let strokeColor: string | number[] = color;

        // Convert if necessary
        if (color instanceof ThreeColor) {
            strokeColor = `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, 1)`;
        } else if (typeof color === 'string') {
            strokeColor = color;
        }

        const line = new Feature({
            geometry: new LineString(coordinates.map((coord) => fromLonLat(coord))),
        });

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: [line],
            }),
            style: new Style({
                stroke: new Stroke({
                    color: strokeColor,
                    width: 2,
                }),
            }),
        });

        this.map.addLayer(vectorLayer);
    }

    clearMarker(coordinate: [number, number], content?: string): void {
        if (!this.map) {
            console.error('Map instance is not initialized.');
            return;
        }

        // Retrieve the source of the markers
        const vectorLayers = this.map.getLayers().getArray().filter(layer => layer instanceof VectorLayer);
        vectorLayers.forEach(layer => {
            const source = (layer as VectorLayer).getSource() as VectorSource;

            // Find the feature to remove
            const features = source.getFeatures();
            const featureToRemove = features.find(feature => {
                const geometry = feature.getGeometry();
                if (geometry instanceof Point) {
                    const coords = geometry.getCoordinates();
                    const lonLat = toLonLat(coords);
                    const matchesCoordinates = lonLat[0] === coordinate[0] && lonLat[1] === coordinate[1];
                    const matchesContent = content ? feature.get('name') === content : true;
                    return matchesCoordinates && matchesContent;
                }
                return false;
            });

            if (featureToRemove) {
                // Remove the feature from the source
                source.removeFeature(featureToRemove);
                console.log('Marker removed:', featureToRemove);
            }
        });
    }

    addTracePaths(tracePaths: any[]) {
        tracePaths.forEach((path) => {
            this.addPolyline(path.points, path.color);
        });
    }

    addPolylineWithArrows(coordinates: [number, number][], color: any) {
        let strokeColor: string | number[] = color;

        // Convert if necessary
        if (color instanceof ThreeColor) {
            strokeColor = `rgba(${color.r * 255}, ${color.g * 255}, ${color.b * 255}, 1)`;
        } else if (typeof color === 'string') {
            strokeColor = color;
        }

        const line = new Feature({
            geometry: new LineString(coordinates.map((coord) => fromLonLat(coord))),
        });
        const source = new VectorSource({
            features: [line],
        });

        const styleFunction = (feature: any) => {
            const geometry = feature.getGeometry();
            const styles = [
                // linestring
                new Style({
                    stroke: new Stroke({
                        color: strokeColor,
                        width: 2,
                    }),
                }),
            ];

            geometry.forEachSegment(function (start: [number, number], end: [number, number]) {
                const dx = end[0] - start[0];
                const dy = end[1] - start[1];
                const rotation = Math.atan2(dy, dx);
                // arrows
                styles.push(
                    new Style({
                        geometry: new Point(end),
                        image: new RegularShape({
                            fill: new Fill({ color: 'white' }),
                            points: 3,
                            radius: 10,
                            rotation: -rotation - Math.PI / 4,
                            scale: 1,
                            rotateWithView: true,
                        }),
                    }),
                );
            });

            return styles;
        };

        const vectorLayer = new VectorLayer({
            source: source,
            style: styleFunction,
            zIndex: 800
        });

        this.map.addLayer(vectorLayer);
    }
}

