import Map from 'ol/Map';
import Draw, { DrawEvent } from 'ol/interaction/Draw';
import { Modify, Snap } from 'ol/interaction';
import VectorSource from 'ol/source/Vector';
import { DrawLayer } from '../ol/drawing-layer';
import { DrawingManagerOption } from '@app/new-ui/location/location.component';
import GeoJSON from 'ol/format/GeoJSON';
import { geojson } from '@lib/geojson';

export class MapInteractionDraw {
    private drawLayer: DrawLayer;

    constructor() {
        this.drawLayer = new DrawLayer(); // Create an instance of DrawLayer
    }

    public addDrawInteraction(map: Map, drawType: DrawingManagerOption): Draw {
        // Initialize the drawing layer and get its vector source
        const vectorSource = this.drawLayer.initializeDrawingLayer(map);

        // Create and add the draw interaction
        const drawInteraction = new Draw({
            source: vectorSource,
            type: drawType,
        });
        map.addInteraction(drawInteraction);
        return drawInteraction;
    }

	public async addDrawInteractionAsync(
        map: Map,
        drawType: DrawingManagerOption
    ): Promise<geojson.Geometry | null> {
        // Initialize the drawing layer and get its vector source
        const vectorSource = this.drawLayer.initializeDrawingLayer(map);

        // Create and add the draw interaction
        const drawInteraction = new Draw({
            source: vectorSource,
            type: drawType,
        });

        map.addInteraction(drawInteraction);

        // Wait for the drawing interaction to complete
        return new Promise((resolve, reject) => {
            const onDrawEnd = (event:DrawEvent) => {
                const geometry = event.feature?.getGeometry();
                if (geometry) {
                    // Convert geometry to GeoJSON
                    const geoJson = new GeoJSON().writeGeometryObject(geometry);
                    resolve(geoJson as geojson.Geometry);
                } else {
                    reject(new Error('No geometry was drawn.'));
                }
                // Remove the interaction and listener after resolving
                map.removeInteraction(drawInteraction);
                drawInteraction.un('drawend', onDrawEnd);
            };

            drawInteraction.on('drawend', onDrawEnd);

            // Optional: Add a timeout to reject the promise if needed
            const timeout = setTimeout(() => {
                reject(new Error('Drawing timed out.'));
                map.removeInteraction(drawInteraction);
                drawInteraction.un('drawend', onDrawEnd);
            }, 60000); // Timeout after 1 minute

            // Clear the timeout if resolved
            drawInteraction.on('drawend', () => clearTimeout(timeout));
        });
    }

    public addModifyAndSnapInteractions(map: Map): void {
        // Retrieve the drawing layer
        const drawingLayer = this.drawLayer.GetDrawngLayer();
        if (!drawingLayer) {
            console.error('Drawing layer not initialized.');
            return;
        }

        const vectorSource = drawingLayer.getSource() as VectorSource;

        // Add modify and snap interactions
        const modifyInteraction = new Modify({ source: vectorSource });
        const snapInteraction = new Snap({ source: vectorSource });

        map.addInteraction(modifyInteraction);
        map.addInteraction(snapInteraction);
    }

    public removeInteraction(map: Map, interaction: Draw | Modify | Snap): void {
        map.removeInteraction(interaction);
    }
}
