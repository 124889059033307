import Tile from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import tile from 'ol/source/Tile'
import { fromLonLat } from 'ol/proj';
// import ol from '@ol';

export class GoogleMapsSource extends XYZ {
    constructor() {
        super({
            url: 'https://mt{0-3}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', // Google Maps satellite layer URL
            attributions: [
                '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
            ],
            crossOrigin: 'anonymous', // Prevents CORS issues with tile fetching
            cacheSize: 64, // Controls the cache size
            maxZoom: 20, // Adjust max zoom as needed
            minZoom: 0, // Minimum zoom level
        });
    }
}

export class GoogleMapsLayer extends Tile<tile> {
    constructor() {
        super({
            // Preload all tiles for better performance
            preload: Infinity,
            visible: true,
            source: new GoogleMapsSource(), // Attach the custom GoogleMapsSource
        });
    }
}

// Export the Google Maps layer instance for easy reuse
export const googleMapsLayer = new GoogleMapsLayer();
export const googleMapsSource = new GoogleMapsSource();
