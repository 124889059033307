import {Injectable} from "@angular/core";
import {DateTime} from "@lib/date-time";
import {BehaviorSubject,OverrideSubject,Subject,combineLatest,debounce,debounceTime,firstValueFrom,interval,of,op} from "@lib/rxjs";
import {storageLocal} from "@lib/storage-local";
import {DataIntervalTag,Device,User,Machine,MachineLocationQuery} from "@models";
import {WebClient} from "./web-client";
import { DialogService } from '@app/old-ui/dialog/dialog.service';
import { User$ } from "./login.service";
import { WebsocketService } from "@app/new-ui/video-streaming/websocket.service";

interface DurationOption {
	label: string;
	type: DateTime.Step;
	key?: string;
}

const durationOptions: DurationOption[] = [
    {
		label: 'last 10 minutes',
		type: [10,'minute'],
	},
    {
		label: 'last 30 minutes',
		type: [30,'minute'],
	},
    {
		label: 'last hour',
		type: [1,'hour'],
	},
	{
		label: 'last 2 hour',
		type: [2,'hour'],
	},
	{
		label: 'Last 3 Hours',
		type: [3,'hour'],
	},
	{
		label: 'Last 6 Hours',
		type: [6,'hour'],
	},
	{
		label: 'Last 12 Hours',
		type: [12,'hour'],
	},
	{
		label: 'Last 24 Hours',
		type: [1,'day'],
	}
];

for (const opt of durationOptions)
	opt.key = Array.isArray(opt.type) ? opt.type.join('') : opt.type;
const durationOptionMap = new Map(durationOptions.map((v) => [v.key, v]));

function liveTimeRange(step:DateTime.Step){
	return interval(5*60*1000)
		.pipe(
			op.startWith(-1),
			op.map(()=>{
				const end=new DateTime();
				const begin=end.clone().sub(step);
				return {
					begin,
					end,
				};
			}));
}

@Injectable({
	providedIn: "root",
})
export class MachineQueryService{
	constructor(
		private readonly webClient:WebClient,
		private readonly user: User,
		private readonly webSocket: WebsocketService
	) {
		this.init();
	}

	//==================== traceing page data variables ==============//
	public readonly devices$=this.webClient.model.device.search$({
		sort: {
			clientId: '+',
			id:'+'
		}
	});
	public readonly durationOptions = durationOptions;
	public readonly defaultDevice$ = this.devices$.pipe(op.map((v) => v?.[0] ?? null));
	public readonly machines$=this.webClient.model.machine.search$({});
	public liveMachine$ = new BehaviorSubject([]);
	public readonly defaultMachine$= this.machines$.pipe(op.map((v)=>v?.[0] ?? null))
	public readonly defaultSelectedMachines$ = this.machines$.pipe(op.map(machines=> [machines[0]] ?? null))
	public readonly sites$=this.webClient.model.blastSite.search$({});
	public readonly device$ = new OverrideSubject(this.defaultDevice$);
	public readonly machine$= new OverrideSubject(this.defaultMachine$);
	public readonly selectedMachines$ = new OverrideSubject(this.defaultSelectedMachines$);
	public readonly duration$ = new BehaviorSubject(durationOptionMap.get(storageLocal.get('machine-query-duration'))?.key ?? '12hour');

	public readonly timeRange$ = this.duration$.pipe(
		op.map((key) => durationOptionMap.get(key)),
		op.switchMap(durOpt => {
			const step = durOpt.type;
			return liveTimeRange(step);
		}));

	public readonly machineLocationForInterval$=combineLatest(
		[
			this.timeRange$,
			this.selectedMachines$,
			this.device$
		]
	).pipe(
		op.debounceTime(0),
		op.switchMap(([timeRange,machines,device])=>{
			machines.map(machine=>machine.id)

			const body: MachineLocationQuery.Interval.Body = {
				email: this.user.email,
				authToken: this.user.authToken,
				machineIds: [0,...machines.map(machine=>machine.id)],
				begin: timeRange.begin,
				end: timeRange.end
			};

			return this.webClient
			.rest$({
				route: 'machine/location/interval',
				method: 'get',
				body,
			})
			.pipe(op.startWith(undefined))
		}),
		op.traverse((r) => r.toRecord<MachineLocationQuery.Interval.Response>()),
		op.traverse((r) => ({
			begin: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.begin) : new DateTime(r.begin).replaceTimezone(this.user.timezoneOffset),
			end: (this.user.timezone === null || this.user.timezone === 'Default') ? new DateTime(r.end) : new DateTime(r.end).replaceTimezone(this.user.timezoneOffset),
			locationPoints:r.locationPoints
		})),
		op.shareReplay(1)
	)

	private init() {
		this.devices$.subscribe((devices) => {
			let device: Device = null;
			if (devices) {
				const uuid = '' + storageLocal.get('frag-query-device-uuid');
				device = devices.find((device) => device.uuid === uuid);
				if (!device) device = devices[0];
			}
			this.device$.next(device);
		});

		this.machines$.subscribe((machines) => {
			let selectedMachines: Machine[] = [];
			if (machines) {
				const ids:number[] = storageLocal.get('selected-machines-id');
				selectedMachines = machines.filter((machine) => ids.includes(machine.id));
				if (!selectedMachines) selectedMachines = [machines[0]];
			}
			if(selectedMachines.length>0){
				this.selectedMachines$.next(selectedMachines);
			}
		});

		this.selectedMachines$.subscribe(machines=>{
			if(machines.length>0){
				storageLocal.set('selected-machines-id',machines.map(selectedMachine=>selectedMachine.id)?? null);
			}
		})

		this.duration$.subscribe((v) => storageLocal.set('machine-query-duration', v));

		this.webSocket.sendMessage({ module:'location', type:'machine'});

		this.webSocket.getMessages().subscribe(
			message => {
				if (message.module == 'location'){
					let updatedMachineLocation = message.machines;
					console.log(updatedMachineLocation);

					this.liveMachine$.next(updatedMachineLocation);
				}

			},
			error => {
			  console.error('WebSocket error:', error);
			}
		  );
	}
}
