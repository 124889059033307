import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { geojson } from '@lib/geojson';
import { MachineQueryService } from 'src/injectables/machine-query.service';
import { MachineLocationQuery } from '../../../../../shared-models/machine-query';
import { BlastSite, Device } from '@models';
import { Color } from '@lib/color';
import { DrawingManagerOption } from '../../new-ui/location/location.component';
import { MapService } from './map.service';
import { MapOverlayController } from 'src/injectables/map-overlay-controller.service';
import { easeIn,easeOut } from 'ol/easing';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

export interface CoordObj {
    lat: number;
    lng: number;
}

export interface CornerLocations {
    start: CoordObj;
    finish: CoordObj;
    name: string;
    color?: Color;
}

export interface TracePath {
    points: CoordObj[];
    color: Color; // Assuming color is a string; adjust if it's a specific type like `Color`
}

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges {
    constructor(
        private readonly machineQuery: MachineQueryService,
        private readonly mapService: MapService,
        private mapOverlayController: MapOverlayController,
    ) {

        this.mapOverlayController.isPersistentTooltip$.subscribe((state) => {
            this.isPersistentTooltip = state;
        });
    }

    @ViewChild('map', { static: false }) mapElement: ElementRef;
    @Input() MachinesPathLocations: MachineLocationQuery.Interval.MachineLocationsWithPathColor[];
    @Input() device: Device;
    @Input() blastSites: BlastSite[];
    @Input() drawingOption: DrawingManagerOption = 'Polygon'; // Supports 'polygon' and 'point'
    @Input() menuControl: boolean = false;
    @Input() showCurrentMachinesLocation: boolean = false;
    @Output() newSitePolygon: EventEmitter<geojson.GeometryPolygon> = new EventEmitter<geojson.GeometryPolygon>();
    @Output() addDeviceLocation: EventEmitter<CoordObj> = new EventEmitter<CoordObj>();
    @Output() changeDeviceLocation: EventEmitter<CoordObj> = new EventEmitter<CoordObj>();
    @Output() toggleMenu: EventEmitter<void> = new EventEmitter<void>();
    @Output() toggleTooltip: EventEmitter<void> = new EventEmitter<void>();

    public machineColor: { name: string, color: Color }[] = [];
    isPersistentTooltip: boolean = false;

    machineTracePaths: TracePath[] = [];
    cornerLocations: CornerLocations[] = [];

    style = {
        radius: 10,
        color: '#5271FF',
        strokeColor: '#fff',
        strokeWidth: 3
    };

    machineStyle = {
        radius: 10,
        color: '#5271FF',
        strokeColor: '#fff',
        strokeWidth: 3
    };

    ngOnChanges(changes: SimpleChanges): void {
        this.updateMap();
    }

    async draw(): Promise<void> {
        await this.mapService.draw(this.drawingOption)
    }

    ngAfterViewInit(): void {
        this.initMap();
        this.updateMap();
    }

    private initMap(): void {
        if (this.device?.lat && this.device?.long) {
            this.mapService.initializeMap(
                { lat: this.device.lat, lng: this.device.long },
                this.mapElement
            );
        } else {
            console.error('Device coordinates are missing.');
        }
    }

    private updateMap(): void {
        if (this.menuControl) {
            this.addCustomControl();
            this.addTooltipToggle();
        }

        if (this.device?.lat && this.device?.long) {
            this.mapService.addMarker(
                { lat: this.device.lat, lng: this.device.long },
                `<strong>Device: </strong> ${this.device.name}`,
                this.changeDeviceLocation,
                '../../../assets/mat-icons/camera.svg',
                { ...this.style, radius: 15, iconScale: 0.30 },
                true,
                true
            );
        }

        if (this.blastSites?.length > 0) {
            this.mapService.addBlastSites(this.blastSites);
        } else {
            this.machineQuery.sites$.subscribe((sites) => {
                this.mapService.addBlastSites(sites);
            });
        }

        // if (this.showCurrentMachinesLocation) {
        //     this.machineQuery.liveMachine$.subscribe((machines) => {
        //             if (this.showCurrentMachinesLocation) {
        //                 machines.forEach((machine) => {
        //                     if (machine.lastLat && machine.lastLong) {
        //                         const matchedColor = this.mapService.machineColor.find(colorEntry => colorEntry.name === machine.name)?.color || this.machineStyle.color;
        //                         const customStyle = {
        //                             ...this.machineStyle,
        //                             color: matchedColor,
        //                             iconScale: 0.25,
        //                             radius: 16
        //                         };
        //                         this.mapService.addMarker(
        //                             { lat: machine.lastLat, lng: machine.lastLong },
        //                             `<strong>Machine: </strong> ${machine.name}`,
        //                             this.changeDeviceLocation,
        //                             '../../../assets/mat-icons/machine.svg',
        //                             customStyle
        //                         );
        //                     }
        //                 });
        //             }
        //     });
        // }

        // Track existing markers by machine ID (or name)
let markersMap: { [key: string]: Feature<Point> } = {}; // Key can be machine.id or machine.name

if (this.showCurrentMachinesLocation) {
    this.machineQuery.liveMachine$.subscribe((machines) => {
        if (this.showCurrentMachinesLocation) {
            machines.forEach((machine) => {
                if (machine.lastLat && machine.lastLong) {
                    const matchedColor = this.mapService.machineColor.find(colorEntry => colorEntry.name === machine.name)?.color || this.machineStyle.color;
                    const customStyle = {
                        ...this.machineStyle,
                        color: matchedColor,
                        iconScale: 0.25,
                        radius: 16
                    };
                    console.log(markersMap)

                    // Check if the marker already exists
                    if (markersMap[machine.id]) {
                        // Update the existing marker's position
                        console.log('already exist')

                        const marker = markersMap[machine.id];
                        const newPoint = new Point(fromLonLat([machine.lastLong, machine.lastLat]));
                        // marker.setGeometry(newPoint)

                        // marker.getGeometry().setCoordinates(newPoint.getCoordinates());

                        // Alternatively, you can manually animate by using a map animation with easing functions
                        this.animateMarkerMovement(marker, newPoint);
                    } else {
                        // Add new marker and store it
                        const newMarker = this.mapService.addMarker(
                            { lat: machine.lastLat, lng: machine.lastLong },
                            `<strong>Machine: </strong> ${machine.name}`,
                            this.changeDeviceLocation,
                            '../../../assets/mat-icons/machine.svg',
                            customStyle
                        );

                        // Store the new marker in the map for future updates
                        markersMap[machine.id] = newMarker;
                    }
                }
            });
        }
    });
}

        if (this.MachinesPathLocations?.length > 0) {
            this.processMachinesPathLocations();
            this.mapService.addTracePaths(this.machineTracePaths, this.cornerLocations);
        }
    }

    // Method to animate the marker movement
private animateMarkerMovement(marker: any, newPoint: Point) {
    // Get the current geometry of the marker
    const currentGeometry = marker.getGeometry();
    const currentCoords = currentGeometry.getCoordinates();

    const newCoords = newPoint.getCoordinates();

    // Use a smooth animation to move the marker
    const duration = 3000; // Set duration of the animation (1 second)

    marker.getGeometry().setCoordinates(currentCoords); // Set the initial position of the marker

    // Start an animation to move the marker from its current position to the new coordinates
    const startTime = Date.now();
    const animate = () => {
        const elapsedTime = Date.now() - startTime;
        const fraction = Math.min(elapsedTime / duration, 1);

        // Calculate the easing factor for smooth animation
        const easingFactor = easeOut(fraction);

        // Interpolate the coordinates between the current and new position
        const interpolatedCoords = [
            currentCoords[0] + (newCoords[0] - currentCoords[0]) * easingFactor,
            currentCoords[1] + (newCoords[1] - currentCoords[1]) * easingFactor,
        ];

        marker.getGeometry().setCoordinates(interpolatedCoords);

        if (fraction < 1) {
            requestAnimationFrame(animate); // Continue the animation
        }
    };

    requestAnimationFrame(animate); // Start the animation
}

    private processMachinesPathLocations(): void {
        this.machineTracePaths = [];
        this.cornerLocations = [];

        this.MachinesPathLocations.forEach((machineLocations) => {
            let startTime = Number.MAX_SAFE_INTEGER;
            let finishTime = 0;
            const loc: CoordObj[] = [];
            const cornerLoc: CornerLocations = {
                start: { lat: 0, lng: 0 },
                finish: { lat: 0, lng: 0 },
                name: '',
            };

            machineLocations.machineLocations.forEach((point) => {
                if (point[0] < startTime) {
                    cornerLoc.start.lat = point[1];
                    cornerLoc.start.lng = point[2];
                    startTime = point[0];
                }
                if (point[0] > finishTime) {
                    cornerLoc.finish.lat = point[1];
                    cornerLoc.finish.lng = point[2];
                    finishTime = point[0];
                }
                loc.push({ lng: point[2], lat: point[1] });
            });

            this.cornerLocations.push({
                name: machineLocations.machineName,
                start: cornerLoc.start,
                finish: cornerLoc.finish,
                color: machineLocations.color,
            });

            this.machineTracePaths.push({
                points: loc,
                color: machineLocations.color,
            });
        });
    }

    private addCustomControl(): void {
        const controlDiv = document.createElement('div');
        controlDiv.style.position = 'absolute';
        controlDiv.style.top = '1.25em'; // Adjust top margin as needed
        controlDiv.style.left = '0.5em'; // Adjust left margin as needed
        controlDiv.style.zIndex = '1000'; // Ensure it appears above other elements

        const controlUI = document.createElement('div');
        controlUI.style.backgroundColor = 'white';
        controlUI.style.border = '2px solid #fff';
        controlUI.style.borderRadius = '2px';
        controlUI.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        controlUI.style.cursor = 'pointer';
        controlUI.style.margin = '10px';
        controlUI.style.textAlign = 'center';
        controlUI.style.justifyContent = 'center';
        controlUI.style.alignItems = 'center';

        controlDiv.appendChild(controlUI);

        const iconComponent = document.createElement('img');
        iconComponent.src = '../../../assets/mat-icons/menu.png';
        iconComponent.height = 30;
        controlUI.appendChild(iconComponent);

        controlUI.addEventListener('click', () => {
            this.toggleMenu.emit();
        });

        this.mapElement.nativeElement.appendChild(controlDiv);
    }

    private addTooltipToggle(): void {
        const tooltipContainer = document.createElement('div');
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.top = '2em'; // Adjust top margin
        tooltipContainer.style.right = '0.75em'; // Adjust right margin
        tooltipContainer.style.zIndex = '1000'; // Ensure it appears above other elements
        tooltipContainer.style.display = 'flex';
        tooltipContainer.style.flexDirection = 'column';
        tooltipContainer.style.alignItems = 'center';
        tooltipContainer.style.gap = '10px'; // Space between buttons

        // Tooltip Button
        const tooltipButton = this.createIconButton(
            '../../../assets/mat-icons/pin.svg',
            'white',
            'Show ToolTips',
            () => this.onTooltipToggle(),
            'transparent',
            '100%'
        );

        // Machine Button
        const machineButton = this.createIconButton(
            '../../../assets/mat-icons/machine.svg',
            '#ff8a52',
            'Show Machine ToolTips',
            () => this.machineTooltipToggle(),
            'transparent'
        );

        // Camera Button
        const deviceButton = this.createIconButton(
            '../../../assets/mat-icons/camera.svg',
            '#ffe152',
            'Show Device ToolTips',
            () => this.deviceTooltipToggle(),
            'transparent',
            '95%'
        );

        // Append buttons to the container
        tooltipContainer.appendChild(tooltipButton);
        // tooltipContainer.appendChild(machineButton);
        // tooltipContainer.appendChild(deviceButton);

        // Append the container to the map element
        this.mapElement.nativeElement.appendChild(tooltipContainer);
    }

    private createIconButton(
        iconSrc: string,
        backgroundColor: string,
        tooltipText: string,
        onClick: () => void,
        borderColor?: string,
        size?: string,
    ): HTMLElement {
        const button = document.createElement('button');
        button.style.width = '40px'; // Set button size
        button.style.height = '40px';
        button.style.backgroundColor = backgroundColor;
        button.style.border = `2px solid ${borderColor || 'transparent'}`;
        button.style.borderRadius = '0.25em'; // Circular button
        button.style.cursor = 'pointer';
        button.style.display = 'flex';
        button.style.alignItems = 'center';
        button.style.justifyContent = 'center';
        button.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)';
        button.style.cursor = 'pointer'; // Ensures the cursor changes immediately
        button.setAttribute('title', tooltipText); // Native tooltip

        const icon = document.createElement('img');
        icon.src = iconSrc;
        icon.style.width = size ? size : '95%'; // Scale icon size
        icon.style.height = size ? size : '95%';

        button.appendChild(icon);

        // Add click event
        button.addEventListener('click', onClick);

        return button;
    }


    private onTooltipToggle(): void {
        console.log('Tooltip toggled!'); // Replace with actual functionality
        this.mapOverlayController.toggleTooltipMode(this.mapOverlayController.isPersistentTooltipSubject);
    }

    private machineTooltipToggle(): void {
        console.log('Machine tooltip toggled!'); // Replace with actual functionality
        this.mapOverlayController.toggleTooltipMode(this.mapOverlayController.isMachineTooltipSubject);
    }
    private deviceTooltipToggle(): void {
        console.log('Device tooltip toggled!'); // Replace with actual functionality
        this.mapOverlayController.toggleTooltipMode(this.mapOverlayController.isDeviceTooltipSubject);
    }
}
