import GeoJSON from 'ol/format/GeoJSON';
import { MapOverlayController } from './../../../injectables/map-overlay-controller.service';
import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { CoordObj, CornerLocations, TracePath } from './map.component';
import { BlastSite, Machine } from '@models';
import { geojson } from '@lib/geojson';
import { DrawingManagerOption } from '../../new-ui/location/location.component';
import { Color } from '@lib/color';
import { Draw, Modify, Snap } from 'ol/interaction';
import Map from 'ol/Map'; // Import OpenLayers Map
import { toLonLat } from 'ol/proj';
import { MapInteractionDraw } from './interactions/draw';


@Injectable({
    providedIn: 'root',
})
export class MapService {
    public map: Map | null = null; // Define the map property
    polygonGeoJson: geojson.GeometryPolygon = {
        type: 'Polygon',
        coordinates: [],
    };
    public machineColor: { name: string; color: Color }[] = [];

    constructor(private overlayController: MapOverlayController) {
        this.overlayController.setMapInstance(this.map)
    }

    public initializeMap(center: CoordObj, mapElement: ElementRef) {
        this.map = this.overlayController.initializeBaseSatelliteLayer(mapElement.nativeElement, [center.lng, center.lat], 15);
    }

    public async draw(
        drawingOption: DrawingManagerOption
    ): Promise<geojson.GeometryPolygon | geojson.GeometryPoint | null> {
        if (!this.map) {
            throw new Error('Map instance is not initialized.');
        }
    
        const mapInteractionDraw = new MapInteractionDraw();
    
        const drawInteraction = await mapInteractionDraw.addDrawInteractionAsync(this.map, drawingOption);

        if (drawingOption === 'Polygon') {
            const transformedCoordinates = Array.isArray(drawInteraction.coordinates) ? (
                Array.isArray(drawInteraction.coordinates[0]) ? 
                    (drawInteraction.coordinates as geojson.Coord[][]).map(ring => 
                        ring.map(coord => {
                            const [lon, lat] = toLonLat(coord);
                            return [lat, lon];
                        })
                    ) : 
                    (drawInteraction.coordinates as geojson.Coord[]).map(coord => {
                        const [lon, lat] = toLonLat(coord);
                        return [lat, lon];
                    })
            ) : null;
    
            return {
                type: 'Polygon',
                coordinates: transformedCoordinates,
            } as geojson.GeometryPolygon;
        } else if (drawingOption === 'Point') {
            return {
                type: 'Point',
                coordinates: toLonLat(drawInteraction.coordinates as [number, number])
            } as geojson.GeometryPoint;
        }
    
        return null;
    }

    public addMarker(
        point: CoordObj,
        title: string,
        editLocationEmitter?: EventEmitter<CoordObj>,
        iconPath?: string,
        style?: any,
        draggable?: boolean,
        isDevice?: boolean
    ) {
        const content = `<div class="map-marker" style="padding: 5px; border-radius: 5px;">
        ${title}
      </div>`;

        const marker = this.overlayController.addMarker([point.lng, point.lat], content, editLocationEmitter, style, iconPath, draggable, isDevice);

        if (!editLocationEmitter) {
            console.warn('Devices Can Be Repositioned By Dragging, Machines Cannot');
        }

        return marker;
    }

    public addBlastSites(blastSites: BlastSite[]) {
        const formattedSites = blastSites.map((site) => {
            const geoJson: geojson.GeometryPolygon = JSON.parse(site.locations);
            return {
                name: site.name,
                coordinates: geoJson.coordinates[0].map((coord) => [coord[0], coord[1]] as [number, number]), // Explicitly cast to [number, number]
            };
        });
        this.overlayController.addBlastSites(formattedSites);
    }

    public addCircle(center: CoordObj, color: string) {
        const radius = 10; // Default radius value
        this.overlayController.addCircle([center.lng, center.lat], radius, color);
    }

    public addPolyline(points: CoordObj[], color: Color) {
        const coordinates: [number, number][] = points.map((point) => [point.lng, point.lat]);
        this.overlayController.addPolyline(coordinates, color);
    }

    public addPolylineWithArrows(points: CoordObj[], color: Color) {
        const coordinates: [number, number][] = points.map((point) => [point.lng, point.lat]);
        this.overlayController.addPolylineWithArrows(coordinates, color);
    }

    public addTracePaths(tracePaths: TracePath[], cornerLocations: CornerLocations[]) {
        tracePaths.forEach((tracePath) => {
            this.addPolylineWithArrows(tracePath.points, tracePath.color);
        });

        cornerLocations.forEach((corner) => {
            this.addMarker(corner.start, `<strong>Start Point :</strong> ${corner.name}`, null, null, { radius: 7, color: corner.color });
            // this.addMarker(corner.finish, 'Fin', null, null, { radius: 5 });
        });
    }
}
