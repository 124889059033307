import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Device, User } from '@models';
import { DialogService } from '../dialog.service';
import { WebClient } from '@injectables';
import { geojson } from '@lib/geojson';

interface AddDeviceLocation {
	point: geojson.GeometryPoint,
	unassignedDevices: Device[]
}
@Component({
  selector: 'app-dialog-set-device-location',
  templateUrl: './dialog-set-device-location.component.html',
})

export class DialogSetDeviceLocationComponent {

    static readonly config:MatDialogConfig<AddDeviceLocation>={
		autoFocus: false,
		disableClose: true,
	};
	static readonly output:boolean;
    constructor(
		public readonly user:User,
		private readonly webClient:WebClient,
		private readonly host:MatDialogRef<boolean>,
		private readonly dialog:DialogService,
		@Inject(MAT_DIALOG_DATA) public readonly input:AddDeviceLocation,
	){}

    public device = this.input.unassignedDevices[0];

	public readonly deviceClient=this.webClient.model.device;

	public cancel(){
		this.host.close(false);
	}

	public async save() {
        this.device.lat=this.input.point.coordinates[1],
        this.device.long=this.input.point.coordinates[0]
        await this.dialog.await(this.deviceClient.save(this.device));
		this.host.close(true);
	}
}
