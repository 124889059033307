import Map from 'ol/Map';
import VectorSource from 'ol/source/Vector';
import { Stroke } from 'ol/style';
import { Fill } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import { Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
    
export class DrawLayer {
    private drawingLayer: VectorLayer | null = null;
    private vectorSource: VectorSource = new VectorSource();

    public initializeDrawingLayer(map: Map): VectorSource {
        if (this.drawingLayer) {
            return this.vectorSource; // Return existing source
        }

        this.drawingLayer = new VectorLayer({
            source: this.vectorSource,
            style: new Style({
                fill: new Fill({ color: 'rgba(255, 255, 255, 0.4)' }),
                stroke: new Stroke({ color: '#3399CC', width: 2 }),
                image: new CircleStyle({    
                    radius: 7,
                    fill: new Fill({ color: '#3399CC' }),
                }),
            }),
        });

        map.addLayer(this.drawingLayer);
        return this.vectorSource;
    }

    public GetDrawngLayer(): VectorLayer {
        return this.drawingLayer;
    }
}